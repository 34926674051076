<template>
  <div class="lot-types-add animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm">
        <div class="card p-1">
          <h4 class="mainTitle mb-3">Lot Type Content</h4>
          <div class="main-contents">
            <fd-select
              v-model="lotType.propertyTypeId"
              label="Property Type"
              class="mb-2"
              :options="propertyTypeOptions"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-input
              type="text"
              label="Lot Type"
              v-model="lotType.name"
              :validators="[validator.required]"
            ></fd-input>
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Create</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import { LotTypeModel } from "@/models";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  name: "lot-types-add",
  data() {
    return {
      propertyTypeOptions: [],

      lotType: {
        name: ""
      },
      validator: {
        required: required
      }
    };
  },
  mounted() {
    this.getPropertyTypeOptions();
  },
  methods: {
    async getPropertyTypeOptions() {
      this.$store.commit("setIsLoading", true);
      let data = await this.$store.dispatch(
        "managePropertyTypes/getAllPropertyTypes",
        {
          limit: 200
        }
      );

      this.propertyTypeOptions = this._.cloneDeep(data.data);
      this.$store.commit("setIsLoading", false);
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageLotTypes/createLotTypes",
          LotTypeModel.postLotTypePayload(this.lotType)
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "New lot type created successfully."
        });
        this.$router.push({ path: "/manage-lot-types" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.lot-types-add {
  form {
    @extend %formDesign;
  }
}
</style>